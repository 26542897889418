var pruffme_landing_news = [];

let newsPerPage = 3;
function getLandingNews(newsPerPageCount = 3) {
    newsPerPage = newsPerPageCount;

    const template = $(translate(`
        <div class="pruffme_lp_news_block_inner">
            <div class="pruffme_lp_news_block_content"></div>
            <div class="pruffme_lp_news_block_actions"></div>
        </div>`));

    $('.pruffme_lp_news_block_main').append(template);


    post('get-public-news', null, function (data) {
        if (data.error) {

        } else {
            if (data.result) {
                pruffme_landing_news = data.result;

                if (pruffme_landing_news.length > 0) {
                    showLandingNews()
                }
            }
        }
    })
}


function showLandingNews () {
    let currentNewsPage = 1;

    if (pruffme_landing_news.length > newsPerPage) {
        const template = $(translate(`
            <div class="pruffme_lp_news_actions_container">
                <button class="pruffme_lp_news_actions_button prev" id="news_show_prev" disabled>
                    <svg>
                        <use xlink:href="#news_icon_left"></use>
                    </svg>
                    <span>{pruffme.footer.news.prev}</span>
                </button>
                <button class="pruffme_lp_news_actions_button next" id="news_show_next">
                    <span>{pruffme.footer.news.next}</span>
                    <svg>
                        <use xlink:href="#news_icon_left"></use>
                    </svg>
                </button>
            </div>
        `));

        const prevButton = template.find('#news_show_prev');
        const nextButton = template.find('#news_show_next');

        prevButton.on('click', function () {
            currentNewsPage -= 1;
            if (currentNewsPage > 1) {
                nextButton.prop('disabled', false);
                prevButton.prop('disabled', false);
            } else if (currentNewsPage === 1) {
                nextButton.prop('disabled', false);
                prevButton.prop('disabled', true);
            }

            redrawNewsItems(currentNewsPage);
        })

        nextButton.on('click', function () {
            currentNewsPage += 1; 
            const nextNewsExists = (pruffme_landing_news.length - (newsPerPage * currentNewsPage)) > 0;

            if (nextNewsExists) {
                nextButton.prop('disabled', false);
                prevButton.prop('disabled', false); 
            } else {
                nextButton.prop('disabled', true);
                prevButton.prop('disabled', false);
            }

            redrawNewsItems(currentNewsPage);
        })

        $('.pruffme_lp_news_block_actions').append(template);
    }

    redrawNewsItems(currentNewsPage);
}

function redrawNewsItems(page) {
    const startIndex = (page - 1) * newsPerPage;
    const endIndex = page * newsPerPage;

    const newsContainer = $('.pruffme_lp_news_block_content');

    newsContainer.empty();
    pruffme_landing_news.slice(startIndex, endIndex).forEach(function (item) {
        const itemEl = getNewsCardItem(item);

        newsContainer.append(itemEl);
    })
}

function getNewsCardItem(news) {
    const template = $(`
        <div class="pruffme_lp_news_card">
            <div class="pruffme_lp_news_card_inner">
                <img class="pruffme_lp_news_card_picture" loading="lazy" decoding="async" alt="Picture for: ${news.name}"></img>
                <div class="pruffme_lp_news_card_label"></div>
                <div class="pruffme_lp_news_card_content"></div>
            </div>
        </div>
    `);

    const content = Base64.decode(news.content);
    const name = news.name;

    template.find('.pruffme_lp_news_card_label').html(name);
    template.find('.pruffme_lp_news_card_content').html(content);

    if (news.logo !== "0") {
        template.find('.pruffme_lp_news_card_picture').attr('src', news.logo);
    }

    template.on('click', function () {
        if (news.link !== "0") {
            openURL(news.link);
        } else {
            openURL('https://blog.pruffme.com/');
        }
    })

    return template;
}

export default getLandingNews;